import MoneyHelp from '../images/moneyhelp.svg';

const WhatIs1Call = () => {
    return (
        <div className="whatis1call">
            <div className='content'>
                <h1>What is 1Call Bookkeeping?</h1>
                <p>1Call Bookkeeping assists small businesses by taking responsibility for tracking and recording their business transactions, allowing them time to concentrate on their core business activities.</p>
            </div>
            <img src={MoneyHelp} alt="illustration" className="illustration"/>
        </div>
    )
}

export default WhatIs1Call;