// import CeoPhoto from '../images/stevejobs2.jpg';

const CeoQuote = () => {
    return (
        <section className="ceo-quote"> 
            {/* <img src={CeoPhoto} alt="CEO" /> */}
            <div>
                <p>“The most successful person is not the one that has the most money in the bank nor the one that has the most real estate properties, but rather, it is the one that impacts most lives. Helping others succeed is a generational asset, with communal benefit, which can stand the test of time”.</p>
                <span className='name'>Henry Nusetor</span>
                <span className='role'>Chief Executive Officer</span>
            </div>
        </section>
    )
}

export default CeoQuote;