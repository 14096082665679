import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <header>
            <Link to='/' className=''>
                <img src={require('../images/logo-1call.png')} alt="logo" className="logo"/>
            </Link>
            <div className='link-container'>
                <Link to='/' className='link'>Home</Link>
                <Link to='/about' className='link'>About</Link>
                <Link to='mailto:1call.bookkeepingservice@gmail.com' className='link'>Email</Link>
            </div>
        </header>
    )
};

export default Navbar;