const Cta = () => {
    return (
        <section className="cta">
            <h2>Let's ease your financial management</h2>
            <p>1Call is one call away.</p>
            <a href='mailto:1call.bookkeepingservice@gmail.com'>Email us</a>
        </section>
    )
}

export default Cta;