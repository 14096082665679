const Footer = () => {
    return (
        <section className="footer">
            <div className='contact-container'>
                <a href="https://www.facebook.com/1call" target='_blank' rel='noreferrer' className="contact">
                    <img src={require('../images/facebook.png')} alt="facebook" className="social-icon" />
                    <span className='facebook'>Facebook</span>    
                    <span className='facebook'>1Call</span>
                </a>

                <div className='contact'>
                    <span class="material-symbols-outlined">call</span>
                    <span>+61 450 246 751 / +61 431 459 583</span>
                </div>
                
                <div className='contact'>
                    <span class="material-symbols-outlined">location_on</span>
                    <span>Perth, Australia</span>
                </div>
            </div>

            <div className='container'>
                <img src={require('../images/logo-1call.png')} alt="logo" className="logo" />
                <div className='copyright'>
                    <p>Copyright © 2024 1CALL BOOKKEEPING &amp; ACCOUNTING SERVICES PTY LTD.</p>
                    <p>All rights reserved.</p>
                </div>
            </div>
        </section>
    )
}

export default Footer;