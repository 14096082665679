const Hero = () => {
    return (
        <section className="hero">
            <div className='container'> 
                <div>
                    <h1>1Call Bookkeeping Services</h1>
                    <span>Convenience with superior quality in bookkeeping</span>
                    <p>We help relieve small business operators of the stress of getting overly involved in bookkeeping and record keeping activities, so they can focus on their core business activities.</p>
                    <a href='mailto:1call.bookkeepingservice@gmail.com'>Email us</a>
                </div>
                <img src={require('../images/illustration.png')} alt="hero" className="illustration"/>
            </div>
        </section>
    )
}
export default Hero;