import WhatIs1Call from "../components/WhatIs1Call";
import Location from "../components/Location";
import CeoQuote from "../components/CeoQuote";
import Team from "../components/Team";
import MissionVision from "../components/MissionVision";
import Cta from "../components/Cta";
import Footer from "../components/Footer";

const About = () => {
    return (
        <div>
            <WhatIs1Call />
            <Location />
            <CeoQuote />
            <Team />
            <MissionVision />
            <Cta />
            <Footer />
        </div>
    )
}

export default About;