// import buildings from '../images/buildings.jpg';

const MissionVision = () => {
    return (
        <section className='mission-vision'>
            <div className='mission'>
                <span class="material-symbols-outlined">flag</span>
                <h2>Mission</h2>
                <p>
                    Our mission is to provide
                    affordable, high-quality, easily accessible, and no-frills bookkeeping services to support the
                    growth of business in Western Australia.
                </p>
            </div>
            <div>
                <span class="material-symbols-outlined">visibility</span>
                <h2>Vision</h2>
                <p>
                    Our vision is to become a provider of bookkeeping services at the least possible cost, using innovative
                    technology to deliver high quality service and customer satisfaction. 
                </p>
            </div>
        </section >
    );
}

export default MissionVision;