const ServiceHighlight = () => {
    return (
        <section className="highlight">
            <img src={require('../images/help.png')} alt="highlight" className="illustration" />
            <div>
                <h3>Complementary Business Advisory</h3>
                <p>Though this is not part of our core business, we provide a minimum of 30 minutes unpaid business advisory service to our clients, who may require such a service. Clients requiring this service would have to explicitly their request known to our team, and the necessary arrangement would be made to address their need.</p>
            </div>
        </section>
    )
}

export default ServiceHighlight;