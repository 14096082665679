import { useEffect } from 'react';
import InfoDetails from '../components/InfoDetails';
import InfoForm from '../components/InfoForm';
import { useInfosContext } from '../hooks/useInfosContext';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Faqs from '../components/Faqs';
import Cta from '../components/Cta';
import Footer from '../components/Footer';

const Home = () => {
    const {infos, dispatch} = useInfosContext();

    useEffect(() => {
        const fetchInfos = async () => {
            const response = await fetch('/api/infos');
            const json = await response.json();
            
            if (response.ok){
                dispatch({type: 'SET_INFOS', payload: json});
            }
        }

        fetchInfos(); 
    }, [dispatch]);
    
    return (
        <div className="home">
            <Hero />
            <Services />
            {/* <Faqs /> */}
            <Cta />
            <Footer />
            {/* <div className="infos">
                {infos && infos.map((info) => (
                    <InfoDetails key={info._id} info={info} />
                ))}
            </div>
            <InfoForm /> */}
        </div>
    );
};

export default Home;