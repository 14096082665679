// import avatar from '../images/stevejobs2.jpg';
// import avatar2 from '../images/emmawatson.jpg';

const Team = () => {
    return (
        <section className='team'>
            <div>
                <h2>Team</h2>
                <p className='intro'>Meet the skilled and experienced team behind our successful financial interventions.</p>
            </div>
            <div className='grid'>
                <div className='block'>
                    <div className='top-row'>
                        {/* <img src={avatar} alt="Henry Nusetor" className="team-img"/> */}
                        <div className='name-role'>
                            <h3>Henry Nusetor</h3>
                            <span>Director</span>
                        </div>
                    </div>
                    <p className='bio'>10+ years of experience in accounting. Expertise in corporate taxes.</p>    
                </div>   
                <div className='block'>
                    <div className='top-row'>
                        {/* <img src={avatar2} alt="Laurette Uwanyuze" className="team-img"/> */}
                        <div className='name-role'>
                            <h3>Laurette Uwanyuze</h3>
                            <span>Director</span>
                        </div>
                    </div>
                    <p className='bio'>10+ years of experience in accounting. Expertise in corporate taxes.</p>    
                </div>   
            </div>
        </section>
    )
}

export default Team;