import ServiceHighlight from "./ServiceHighlight";

const Services = () => {
    return (
        <section className="services">
            <span className="hook">Manage your entire finances with a single agency</span>
            <h2 className='topic'>What 1Call can help you with:</h2>
            <div className='grid'>
                <div className='block'>
                    <span class="material-symbols-outlined icon">keyboard</span>
                    <h3>Financial data entry</h3>
                    <p>Financial data entry is no longer an issue for the business owner to worry about. We take care of the responsibility of competing data entry activities that relate to your business.</p>
                </div>
                <div className='block'>
                    <span class="material-symbols-outlined icon">inventory</span>
                    <h3>Financial record keeping</h3>
                    <p>Recording every financial transaction, including sales, purchases, and expenses into an accounting system, on a periodic basis.</p>
                </div>
                <div className='block'>
                    <span class="material-symbols-outlined icon">account_balance</span>
                    <h3>Bank Reconciliation</h3>
                    <p>Reconciliation of the entity's bank statement balance with its financial records, ensuring that there is a match between the entity’s cashflow and its bank balance. This process would help pick up any cashflow that is unaccounted for.</p>
                </div>
                <div className='block'>
                    <span class="material-symbols-outlined icon">payments</span>
                    <h3>Account Receivable and Payable</h3>
                    <p>Managing account receivables by tracking customer payments received and managing payments to vendors, in a way that provides optimum advantage to the entity.</p>
                </div>
                <div className='block'>
                    <span class="material-symbols-outlined icon">user_attributes</span>
                    <h3>Payroll Processing</h3>
                    <p>Managing payrolls, including processing employee wages, preparing pay runs and compliance with pay as you go (PAYG) withholdings.</p>
                </div>
                <div className='block'>
                    <span class="material-symbols-outlined icon">monitoring</span>
                    <h3>Financial Reporting</h3>
                    <p>Preparation of financial statements including income statements, balance sheets, and cash flow statements, to help entities make informed decisions.</p>
                </div>
            </div>
            <ServiceHighlight />
        </section>
    )
}

export default Services;