import { InfosContext } from "../context/InfoContext";
import { useContext } from "react";

export const useInfosContext = () => {
    const context = useContext(InfosContext);

    if (!context) {
        throw Error('useInfosContext must be used within a InfosContextProvider');
    }

    return context;
}