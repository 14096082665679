import { createContext, useReducer } from 'react';

export const InfosContext = createContext();

export const infosReducer = (state, action) => {
    switch (action.type) {
        case 'SET_INFOS':
            return {
                infos: action.payload 
            }
        case 'CREATE_INFO':
            return {
                infos: [action.payload, ...state.infos]
            }
        case 'DELETE_INFO':
            return {
                infos: state.infos.filter((info) => info._id !== action.payload._id)
            }
        default:
            return state;
    }   
}

export const InfosContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(infosReducer, {
        infos: null
    })
    return (
        <InfosContext.Provider value={{...state, dispatch}}>
            {children}
        </InfosContext.Provider>
    )
}